import React from 'react'
import { useState, useEffect, useRef, useCallback } from 'react';
import { getNFTTasks } from "../api/nft";
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import {
    Row,
    Col,
    Card,
    Table,
    Button,
    Input,
    Space,
} from "antd";
import { Link } from 'react-router-dom';
import Moment from "react-moment";

const NFTTask = () => {
    const [page, setPage] = useState(1);
    const [isSpinning, setIsSpinning] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [tasks, setTasks] = useState([]);

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const getTaskList = useCallback(async () => {
        setIsSpinning(true);
        var taskList;
        taskList = await getNFTTasks();
        setTasks(taskList);
        setIsSpinning(false);
    }, [])

    useEffect(() => {
        getTaskList();
    }, [getTaskList]);

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: "Index",
            key: "index",
            render: (value, item, index) => (page - 1) * 10 + index + 1
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            ...getColumnSearchProps('action'),
        },
        {
            title: "Element name",
            dataIndex: "input",
            key: "songName",
            render: (_, record) => {
                return (
                    <Link to={`./song/${record.element}`}>{JSON.parse(record.input).title}</Link>
                )
            }
        },
        {
            title: "Amount",
            dataIndex: "input",
            key: "amount",
            render: (_, record) => {
                return (
                    JSON.parse(record.input).amount
                )
            }
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (_, record) => {
                return (
                    record.status ? "True" : "False"
                )
            }
        },
        {
            title: "Last Update",
            dataIndex: "lastUpdate",
            key: "lastUpdate",
            render: (_, record) => {
                return <Moment date={record.lastUpdate} format="YYYY-MM-DD hh:mm" />;
            }
        },
    ]
    return (
        <div className="tabled">
            <Row gutter={[24, 0]}>
                <Col xs="24" xl={24}>
                    <Card
                        bordered={true}
                        className="criclebox tablespace mb-24"
                        title="NFT Tasks"
                    >
                        <div className="table-responsive">
                            < Table
                                {
                                ...{
                                    loading: isSpinning
                                }
                                }
                                style={{ margin: '10px' }}
                                rowKey="order"
                                dataSource={tasks}
                                bordered
                                pagination={{
                                    onChange(current) {
                                        setPage(current);
                                    }
                                }}
                                columns={columns}
                            >
                            </Table >
                        </div>
                    </Card>
                </Col>
            </Row>
        </div >
    )
}

export default NFTTask;