// import { useState } from "react";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { BsGift, BsUpload, BsFillAwardFill, BsCodeSlash, BsCurrencyDollar, BsFillPeopleFill, BsMusicNoteBeamed, BsFillGearFill, BsCalendar2Event } from "react-icons/bs";

function Sidenav() {
  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
        <span>Staff Portal</span>
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        <Menu.Item className="menu-item-header" key="management">
          Management
        </Menu.Item>
        <Menu.Item key="user">
          <NavLink to="/user">
            <span
              className="icon"
            >
              <BsFillPeopleFill />
            </span>
            <span className="label">User</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="song">
          <NavLink to="/song">
            <span
              className="icon"
            >
              <BsMusicNoteBeamed />
            </span>
            <span className="label">Song</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="payment">
          <NavLink to="/payment">
            <span
              className="icon"
            >
              <BsCurrencyDollar />
            </span>
            <span className="label">Payment Record</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item className="menu-item-header" key="action">
          Action
        </Menu.Item>
        <Menu.Item key="uploadFile">
          <NavLink to="/file/upload">
            <span
              className="icon"
            >
              <BsUpload />
            </span>
            <span className="label">Upload File</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="uploadFileToIpfs">
          <NavLink to="/file/ipfs/upload">
            <span
              className="icon"
            >
              <BsCodeSlash />
            </span>
            <span className="label">Upload to IPFS</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item className="menu-item-header" key="nft">
          NFT
        </Menu.Item>
        <Menu.Item key="airdrop">
          <NavLink to="/airdrop">
            <span
              className="icon"
            >
              <BsGift />
            </span>
            <span className="label">NFT Airdrop</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="nftItem">
          <NavLink to="/nft-item">
            <span
              className="icon"
            >
              <BsFillAwardFill />
            </span>
            <span className="label">NFT Item</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="nftTask">
          <NavLink to="/nft-task">
            <span
              className="icon"
            >
              <BsCodeSlash />
            </span>
            <span className="label">NFT Task</span>
          </NavLink>
        </Menu.Item>
        {/* <Menu.Item key="uploadFile">
          <NavLink to="/upload-file-to-ipfs">
            <span
              className="icon"
            >
              <BsCodeSlash />
            </span>
            <span className="label">Upload to IPFS</span>
          </NavLink>
        </Menu.Item> */}
        <Menu.Item className="menu-item-header" key="event">
          Event
        </Menu.Item>
        <Menu.Item key="events">
          <NavLink to="/event">
            <span
              className="icon"
            >
              <BsCalendar2Event />
            </span>
            <span className="label">Events</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item className="menu-item-header" key="setting">
          Setting
        </Menu.Item>
        <Menu.Item key="keyValuePair">
          <NavLink to="/setting">
            <span
              className="icon"
            >
              <BsFillGearFill />
            </span>
            <span className="label">Config Setting</span>
          </NavLink>
        </Menu.Item>
      </Menu>
    </>
  );
}

export default Sidenav;
