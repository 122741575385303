import { getUserListAsync } from "../api/user";
import { useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { Row, Col, Card, Table, Button, Input, Space } from "antd";
import ExcelButton from "../components/ExportToExcelButton";

const Users = () => {
  const [page, setPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [isSpinning, setIsSpinning] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const getUserList = useCallback(async () => {
    setIsSpinning(true);
    var userList;
    userList = await getUserListAsync();
    setUsers(userList);
    setIsSpinning(false);
  }, []);

  useEffect(() => {
    getUserList();
  }, [getUserList]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Index",
      key: "index",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "User ID",
      dataIndex: "id",
      key: "id",
      ...getColumnSearchProps("id"),
      render: (_, record) => {
        return (
          <Link to={"./user/" + record.id} key={record.id + "Detail"}>
            {record.id}
          </Link>
        );
      },
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      ...getColumnSearchProps("username"),
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (_, record) =>
        record.image === "" ? (
          <></>
        ) : (
          <a rel="noreferrer" href={record.image} target="_blank">
            View Image
          </a>
        ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      ...getColumnSearchProps("phone"),
    },
    {
      title: "Action",
      key: "action",
      // render={(_, record) => (
      //     <Space size="middle">
      //         <Link to={"./user/" + record.id} key={record.id + "Detail"}>Details</Link>
      //     </Space>
      // )}
    },
  ];
  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={true}
            className="criclebox tablespace mb-24"
            title="Users Table"
            extra={
              <ExcelButton
                data={users}
                displayMessage={"Export to excel"}
                filename={"Users-Records"}
              />
            }
          >
            <div className="table-responsive">
              <Table
                {...{
                  loading: isSpinning,
                }}
                style={{ margin: "10px" }}
                rowKey="id"
                dataSource={users}
                bordered
                pagination={{
                  onChange(current) {
                    setPage(current);
                  },
                }}
                columns={columns}
              ></Table>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Users;
