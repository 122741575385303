import { useParams } from "react-router-dom";
import {
    Button,
    Form,
    Image as ImageTag,
    Input,
    Upload,
    Switch,
    Spin,
    message,
    Modal
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useState, useEffect, useCallback } from 'react';
import { getSongByIdAsync, deleteSongByIdAsync, updateSongByIdAsync } from '../api/song';
import { useHistory } from "react-router-dom";

const { TextArea } = Input;

const SongDetail = () => {
    let history = useHistory();
    const [title, setTitle] = useState();
    const [artist, setArtist] = useState();
    const [description, setDescription] = useState();
    const [lyric, setLyric] = useState();
    const [imageURL, setImageURL] = useState();
    const [imageFile, setImageFile] = useState();
    const [fileList, setFileList] = useState([]);
    const [isCompetition, setIsCompetition] = useState(true);
    const [isSpinning, setIsSpinning] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    let { id } = useParams();

    const onChange = (checked) => {
        setIsCompetition(checked);
    };

    const getSongDetail = useCallback(async () => {
        setIsSpinning(true);
        var record = {
            "id": "",
            "title": "",
            "image": "",
            "artist": "",
            "desc": "",
            "lyric": "",
            "isCompetition": "",
        };
        record = await getSongByIdAsync(id);
        setTitle(record.title);
        setArtist(record.artist);
        setDescription(record.desc);
        setLyric(record.lyric);
        setImageURL(record.image);
        setIsCompetition(record.isCompetition);
        setFileList([]);
        setIsSpinning(false);
    }, [id])

    useEffect(() => {
        getSongDetail();
    }, [id, getSongDetail]);

    async function deleteSong() {
        await deleteSongByIdAsync(id);
        history.push("./song");
    }

    async function updateSong() {
        setIsSpinning(true);
        await updateSongByIdAsync(id, title, imageFile, description, lyric, isCompetition);
        message.success('Update success!');
        getSongDetail();
    }

    function customRequest({ file, onError, onSuccess }) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        setFileList([file]);
        return reader.onloadend = function (e) {
            var image = new Image();
            image.src = this.result;
            image.onload = function () {
                var height = this.height;
                var width = this.width;
                if (height === width) {
                    onSuccess("ok");
                    setImageFile(file);
                } else {
                    message.error("The height : width of the img should be 1 : 1");
                    setFileList();
                    onError();
                }
            };
        };
    }

    const onReset = () => {
        getSongDetail();
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        deleteSong();
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div>
            <Form
                labelCol={{
                    span: 4,
                }}
                wrapperCol={{
                    span: 14,
                }}
                layout="horizontal"
            >
                <Spin spinning={isSpinning} >
                    <Form.Item label="Id">
                        <Input disabled value={id} name="id" />
                    </Form.Item>
                    <Form.Item label="Artist">
                        <Input disabled value={artist} />
                    </Form.Item>
                    <Form.Item label="Cover">
                        <ImageTag
                            style={{ marginLeft: "3px" }}
                            width={200}
                            height={200}
                            src={imageURL}
                        />
                    </Form.Item>
                    <Form.Item label="Update Cover">
                        <Upload
                            name="image"
                            accept=".jpg"
                            maxCount="1"
                            customRequest={(customRequest)}
                            multiple={false}
                            listType='picture'
                            fileList={fileList}
                        >
                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item label="Title">
                        <Input name="title" onChange={(e) => { setTitle(e.target.value) }} value={title} />
                    </Form.Item>
                    <Form.Item label="Description">
                        <TextArea name="introduction" onChange={(e) => { setDescription(e.target.value) }} rows={4} value={description} />
                    </Form.Item>
                    <Form.Item label="Lyric">
                        <TextArea name="lyric" onChange={(e) => { setLyric(e.target.value) }} rows={15} value={lyric} />
                    </Form.Item>
                    <Form.Item label="Is Competition">
                        <Switch checked={isCompetition} onChange={onChange} />
                    </Form.Item>
                    <Form.Item label="Action">
                        <Button onClick={() => { updateSong() }}>Save</Button>
                        <Button onClick={() => { onReset() }}>Cancel</Button>
                        <Button danger onClick={() => { showModal() }}>Delete</Button>
                    </Form.Item>
                </Spin>
            </Form>
            <Modal okText="Yes" title={`Delete Song "${title}"?`} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <p >You are deleting song "<b>{title}</b>".<br />And this action can not be revert.<br />Are you sure?</p>
            </Modal>
        </div>
    );
};

export default SongDetail;