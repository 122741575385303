import { Form, Input, Button, Spin } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { mintNFTAsync } from '../api/nft';
import {
    message,
} from 'antd';

const SongMint = () => {
    const [isSpinning, setIsSpinning] = useState(false);
    const [mintQuantity, setMintQuantity] = useState(1);

    let { id } = useParams();

    const mintNFT = async () => {
        setIsSpinning(true);
        setIsSpinning(false);
        message.info("Mint request created..");
        await mintNFTAsync(id, mintQuantity);
    }
    return (
        <Form
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 12,
            }}
            layout="horizontal">
            <Spin spinning={isSpinning}>
                <Form.Item label="How many NFT do you wanna mint?">
                    <Input type={"number"} value={mintQuantity} onChange={(e) => { setMintQuantity(e.target.value) }} />
                </Form.Item>
                <Form.Item label="Action">
                    <Button onClick={() => { mintNFT() }}>Mint</Button>
                    <Button onClick={() => { }}>Reset</Button>
                </Form.Item>
            </Spin>
        </Form>);
}

export default SongMint;