import * as React from 'react'

import { useParams } from "react-router-dom";
import { Form, Input, Spin } from 'antd';
import { findTransaction } from "../../api/ticket";

const { TextArea } = Input;

export default function TicketDetail(props) {
  const { id } = useParams();
  const [isSpinning, setIsSpinning] = React.useState(false);
  const [ticket, setTicket] = React.useState([])

  React.useEffect(() => {
    fetchTicketDetail(id)
  }, [id])

  const fetchTicketDetail = async (ticketId) => {
    setIsSpinning(true);
    const result = await findTransaction(ticketId)
    setTicket(result);
    setIsSpinning(false);
  }

  return (
    <div>
      <Spin spinning={isSpinning}>
        <Form
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 14,
          }}
          layout="horizontal"
        >
          <Form.Item label="Id">
            <Input name="id" disabled value={ticket.id} />
          </Form.Item>
          <Form.Item label="Payment Reference">
            <Input name="paymentReference" disabled value={ticket.paymentReference} />
          </Form.Item>
          <Form.Item label="Timestamp">
            <Input name="timestamp" disabled value={ticket.timestamp} />
          </Form.Item>
          <Form.Item label="Details">
            <TextArea name="details" disabled value={ticket.details} rows={12}/>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};