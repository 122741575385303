import * as React from 'react'

import { Row, Col, Card, Checkbox, Table, Space, Input, Button, Modal } from "antd";
import { getEvents, removeEvent, upsertEvent, createTicket, handmadeTicket } from "../../api/ticket";
import moment from 'moment';
import { Link } from 'react-router-dom';
import _ from 'lodash'

import Event from './Event';

const defaultTicketDetail = {
  id: '',
  email: '',
  eventId: '',
  identity: '',
  memberId: '',
  member: false,
}

export default function Events(props) {
  const [events, setEvents] = React.useState([])
  const [page, setPage] = React.useState(1);
  const [eventId, setEventId] = React.useState();
  const [title, setTitle] = React.useState();
  // const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isSpinning, setIsSpinning] = React.useState(false);
  const [selectedEvent, setSelectedEvent] = React.useState();
  const [openDialog, setOpenDialog] = React.useState(false)
  const [emailDialog, setEmailDialog] = React.useState(false)
  const [amount, setAmount] = React.useState(0)
  const [duplicate, setDuplicate] = React.useState();
  const [detail, setDetail] = React.useState(defaultTicketDetail);

  React.useEffect(() => {
    fetchEvents()
  }, [])

  const fetchEvents = async () => {
    setIsSpinning(true);
    const result = await getEvents()
    setEvents(result);
    setIsSpinning(false);
  }

  const resetDetail = () => {
    setDetail(defaultTicketDetail)
  }

  const setTicketDetail = (key, value) => {
    setDetail({
      ...detail,
      [key]: value
    })
  }

  const columns = [
    {
      title: "Index",
      key: "index",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (_, record) => {
        return (
          <Button
            onClick={() => setSelectedEvent(record)}
          >
            {record.title}
          </Button>
        );
      },
    },
    {
      title: "Tickets",
      key: "tickets",
      render: (_, record) => {
        return (
          <Link to={"./event-tickets/" + record.id} key={record.id + "event-tickets"}>
            Tickets Record
          </Link>
        );
      },
    },
    {
      title: "Venue",
      dataIndex: "venue",
      key: "venue",
    },
    {
      title: "Quota",
      dataIndex: "quota",
      key: "quota",
    },
    {
      title: "Quota per user",
      dataIndex: "memberQuota",
      key: "memberQuota",
    },
    {
      title: "Quota(Member Price) per user",
      dataIndex: "publicQuota",
      key: "publicQuota",
    },
    {
      title: "time",
      key: "time",
      render: (value, item, index) => {
        const from = moment(value.eventStart).format('YYYY/MM/DD HH:mm')
        const to = moment(value.eventEnd).format('HH:mm')
        return from + ' - ' + to
      },
    },
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={async () => {
              setOpenDialog(true)
              setEventId(record.id)
              setTitle(record.title)
            }}
          >
            Create Tickets
          </Button>
          <Button
            danger
            onClick={() => {
              setDuplicate(record)
            }}
          >
            Duplicate
          </Button>
        </Space>
      ),
    },
  ];

  if (selectedEvent) {
    return (
      <Event
        item={selectedEvent}
        handleDelete={async () => await removeEvent(selectedEvent.id)}
        onBack={() => setSelectedEvent()}
        handleSave={async (e) => {
          await upsertEvent(e)
          await fetchEvents()
          setSelectedEvent()
        }}
      />
    )
  }

  return (
    <div>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={true}
              className="criclebox tablespace mb-24"
              title="Events"
              extra={
                <>
                  <Button
                    onClick={() => setEmailDialog(true)}
                    style={{marginRight: 10}}
                  >
                    Send QR
                  </Button>
                  <Button
                    onClick={() => setSelectedEvent(defaultData)}
                  >
                    New Event
                  </Button>
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  {...{
                    loading: isSpinning,
                  }}
                  style={{ margin: "10px" }}
                  rowKey="id"
                  dataSource={events}
                  bordered
                  columns={columns}
                  pagination={{
                    onChange(current) {
                      setPage(current);
                    },
                  }}
                ></Table>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal
        okText="Yes"
        title={`Duplicate Event "${duplicate?.title}"?`}
        open={duplicate}
        onOk={async () => {
          const newEvent = _.clone(duplicate)
          delete newEvent.id
          setDuplicate()
          await upsertEvent(newEvent)
          await fetchEvents()
        }}
        onCancel={() => setDuplicate()}
      />
      <Modal
        okText="Yes"
        title={title}
        open={openDialog}
        onOk={async () => {
          await createTicket(eventId, amount)
          setAmount(0)
          setOpenDialog(false)
          fetchEvents()
        }}
        onCancel={() => setOpenDialog(false)}
      >
        <p>
          Create Ticket Amount:
        </p>
        <Input name="tickets" value={amount} onChange={(e) => { setAmount(e.target.value) }} />
      </Modal>
      <Modal
        okText="Yes"
        title='Send QR code email'
        open={emailDialog}
        onOk={async () => {
          try {
            const data = {
              id: detail.id,
              email: detail.email,
              eventId: detail.eventId,
              identity: `${detail.identity} (Paid)${detail.member ? ` [${detail.memberId}]` : ''}`,
              member: false,
            }
            handmadeTicket(data)
            resetDetail()
            setEmailDialog(false)
          } catch (e) {
            console.log(e)
          }
        }}
        onCancel={() => {
          resetDetail()
          setEmailDialog(false)
        }}
      >
        <p>Transaction ID:</p>
        <Input name="id" value={detail.id} onChange={(e) => setTicketDetail('id', e.target.value)} />
        <p>Email:</p>
        <Input name="email" value={detail.email} onChange={(e) => setTicketDetail('email', e.target.value)} />
        <p>Event ID:</p>
        <Input name="eventId" value={detail.eventId} onChange={(e) => setTicketDetail('eventId', e.target.value)} />
        <p>Name:</p>
        <Input name="identity" value={detail.identity} onChange={(e) => setTicketDetail('identity', e.target.value)} />
        <p>Member ID:</p>
        <Input name="memberId" value={detail.memberId} onChange={(e) => setTicketDetail('memberId', e.target.value)} />
        <Checkbox checked={detail.member} onChange={(e) => setTicketDetail('member', e.target.checked)}>Is Member</Checkbox>
      </Modal>
    </div>
  );
};

const defaultData = {
  activityType: '',
  activityType_chinese: '',
  category: '',
  category_chinese: '',
  description: '',
  description_chinese: '',
  endDate: moment().format(),
  eventEnd: moment().format(),
  eventStart: moment().format(),
  memberPrice: '',
  memberQuota: '',
  originalPrice: '',
  publicQuota: '',
  quota: '',
  startDate: moment().format(),
  ticketImage: '',
  ticketMessage: '',
  title: '',
  title_chinese: '',
  totalTicketGenerated: 0,
  totalTicketHold: 0,
  totalTicketSold: 0,
  venue: '',
  venue_chinese: '',
  hide: false
}
