import axios from 'axios';
import Paths from '../config/Paths';

export async function getPaymentListAsync() {
    var result;
    await axios
        .get(Paths.Payments)
        .then(function (response) {
            result = response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
    return result;
}

export async function getPaymentByPaymentId(paymentId) {
    var result;
    await axios
        .get(
            Paths.Payment,
            {
                params: { reference: paymentId }
            })
        .then(function (response) {
            result = response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
    return result;
}