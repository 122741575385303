import axios from 'axios';
import Paths from '../config/Paths';

export async function getSettingListAsync() {
    var result;
    await axios
        .get(Paths.Settings)
        .then(function (response) {
            result = response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
    return result;
}

export async function getSettingByKey(key) {
    var result;
    await axios
        .get(
            Paths.Setting,
            {
                params: { key: key }
            })
        .then(function (response) {
            result = response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
    return result;
}

export async function updateSettingAsync(key, content) {
    var result;
    var formData = new FormData();
    formData.append("key", key);
    formData.append("content", content);
    await axios
        .post(
            Paths.SettingEdit,
            formData)
        .then(function (response) {
            result = response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
    return result;
}
