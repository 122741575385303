import { utils, writeFile } from "xlsx";
import {
    Button,
} from "antd";
const downloadExcel = (data, filename) => {
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    writeFile(workbook, `${filename}.xlsx`);
};

const ExcelButton = ({ data, displayMessage, filename }) => {
    return (
        <Button onClick={() => { downloadExcel(data, filename) }}>
            {displayMessage}
        </Button>
    );
};

export default ExcelButton;