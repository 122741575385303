import React from "react";
import { useState } from "react";
import { Button, Form, Input, Spin, TreeSelect, Checkbox, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { uploadFileToIpfsAsync } from "../api/action";
import { getSongListAsync } from "../api/song";

const FileUploadIPFS = () => {
  const [treeData, setTreeData] = useState([{ title: "", value: "" }]);
  const [isInternalSong, setIsInternalSong] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const [url, setUrl] = useState();
  const [filename, setFilename] = useState();
  const [result, setResult] = useState();
  async function getSongList() {
    setIsSpinning(true);
    let songs = await getSongListAsync();
    setTreeData(
      songs.map(function (item) {
        return {
          title: `${item.title} (${item.artist})`,
          value: item.url + "||" + item.title + ".mp3",
        };
      })
    );
    setIsSpinning(false);
  }

  function onReset() {
    setFilename("");
    setUrl("");
  }
  async function upload(e) {
    try {
      if (!url || !filename) {
        message.error("Please input every rows.");
        return;
      }
      message.info("It may take a while according to your file size...");
      setIsSpinning(true);
      let ipfsURL = await uploadFileToIpfsAsync(url, filename);
      setIsSpinning(false);
      message.success("Upload Success!");
      setResult(ipfsURL);
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }

  return (
    <Form
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 14,
      }}
      layout="horizontal"
    >
      <Spin spinning={isSpinning}>
        <Form.Item label="Song From Muxic">
          <Checkbox
            checked={isInternalSong}
            onChange={(e) => {
              setIsInternalSong(e.target.checked);
              if (e.target.checked) {
                getSongList();
              }
            }}
          ></Checkbox>
        </Form.Item>
        <Form.Item label="Song">
          <TreeSelect
            disabled={!isInternalSong}
            aria-required
            showSearch
            style={{
              width: "100%",
            }}
            dropdownStyle={{
              maxHeight: 400,
              overflow: "auto",
            }}
            placeholder="Please select a song"
            allowClear
            treeDefaultExpandAll
            onChange={(v) => {
              if (v) {
                setUrl(v.split("||")[0]);
                setFilename(v.split("||")[1]);
              }
            }}
            treeData={treeData}
          />
        </Form.Item>
        <Form.Item label="File URL">
          <Input
            disabled={isInternalSong}
            type="text"
            value={url}
            onChange={(e) => {
              setUrl(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item label="File Name">
          <Input
            disabled={isInternalSong}
            type="text"
            value={filename}
            onChange={(e) => {
              setFilename(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item label="Action">
          <Button
            onClick={() => {
              upload();
            }}
          >
            Upload
          </Button>
          <Button
            onClick={() => {
              onReset();
            }}
          >
            Cancel
          </Button>
        </Form.Item>
        {typeof result != "undefined" ? (
          <Form.Item label="Result">
            <TextArea disabled value={result} rows={4}></TextArea>
          </Form.Item>
        ) : (
          <></>
        )}
      </Spin>
    </Form>
  );
};

export default FileUploadIPFS;
