import { getPaymentListAsync } from '../api/payment';
import { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import {
    Row,
    Col,
    Card,
    Table,
    Button,
    Input,
    Space,
} from "antd";
import ExcelButton from "../components/ExportToExcelButton";

const Payments = () => {
    const [page, setPage] = useState(1);
    const [payments, setPayments] = useState([]);
    const [isSpinning, setIsSpinning] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const getPaymentList = useCallback(async () => {
        setIsSpinning(true);
        var paymentList;
        paymentList = await getPaymentListAsync();
        setPayments(paymentList);
        setIsSpinning(false);
    }, [])

    useEffect(() => {
        getPaymentList();
    }, [getPaymentList]);

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: "Index",
            key: "index",
            render: (value, item, index) => (page - 1) * 10 + index + 1
        },
        {
            title: "User",
            dataIndex: "user",
            key: "user",
            ...getColumnSearchProps('user'),
            render: (_, record) => {
                return <Link to={"./payment/" + record.payment} key={record.payment + "Detail"}>{record.user}</Link>
            }
        },
        {
            title: "Song",
            dataIndex: "song",
            key: "song",
            ...getColumnSearchProps('song'),
        },
        {
            title: "Action",
            key: "action",
            render: (_, record) => (
                <Space size="middle">
                    <Link to={"./payment/" + record.payment} key={record.payment + "Detail"}>Details</Link>
                </Space>
            )
        }
    ]
    return (
        <div className="tabled">
            <Row gutter={[24, 0]}>
                <Col xs="24" xl={24}>
                    <Card
                        bordered={true}
                        className="criclebox tablespace mb-24"
                        title="Payments Table"
                        extra={
                            < ExcelButton data={payments} displayMessage={"Export to excel"} filename={"Payment-Records"} />
                        }
                    >
                        <div className="table-responsive">
                            < Table
                                {...{ loading: isSpinning }}
                                style={{ margin: '10px' }}
                                rowKey="payment"
                                dataSource={payments}
                                bordered
                                pagination={{
                                    onChange(current) {
                                        setPage(current);
                                    }
                                }}
                                columns={columns}
                            >
                            </Table >
                        </div>
                    </Card>
                </Col>
            </Row>
        </div >
    )
};

export default Payments;