import * as React from 'react'
import { Row, Col, Card, Table } from "antd";
import { allTickets } from "../../api/ticket";
import { Link } from 'react-router-dom';
import { MdDone, MdClose } from "react-icons/md";
import { useParams } from "react-router-dom";
import moment from 'moment';
import _ from 'lodash'
import ExcelButton from "../../components/ExportToExcelButton";

export default function EventTicketRecord(props) {
  const [tickets, setTickets] = React.useState([])
  const [page, setPage] = React.useState(1);
  const [isSpinning, setIsSpinning] = React.useState(false);
  const { id } = useParams();

  React.useEffect(() => {
    fetchTickets(id)
  }, [id])

  const fetchTickets = async (eventId) => {
    setIsSpinning(true);
    const result = await allTickets(eventId)
    const tickets = _.map(result.tickets, (t) => {
      try {
        const data = JSON.parse(t.otherInfo)
        t.email = data.email
        t.phone = data.phoneNumber
      } catch (error) {}
      return t
    })
    setTickets(tickets);
    setIsSpinning(false);
  }

  const columns = [
    {
      title: "Index",
      key: "index",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "Order Ref",
      key: "orderRef",
      render: (_, record) => {
        return (
          <Link to={"../event-ticket/" + record.orderRef} key={record.orderRef + "Detail"}>
            {record.orderRef}
          </Link>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Member",
      key: "member",
      render: (value, item, index) => value.member ? <MdDone /> : <MdClose />,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Paid",
      key: "paid",
      render: (value, item, index) => value.paid ? <MdDone /> : <MdClose />,
    },
    {
      title: "Payment Date",
      key: "paymentDate",
      render: (value, item, index) => moment(value.paymentDate).add(8, 'h').format('YYYY/MM/DD HH:mm'),
    },
  ];

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={true}
            className="criclebox tablespace mb-24"
            title="Events"
            extra={
              <ExcelButton
              data={tickets}
              displayMessage={"Export to excel"}
              filename={"Tickets-Records"}/>
            }
          >
            <div className="table-responsive">
              <Table
                {...{
                  loading: isSpinning,
                }}
                style={{ margin: "10px" }}
                rowKey="key"
                dataSource={tickets}
                bordered
                columns={columns}
                pagination={{
                  onChange(current) {
                    setPage(current);
                  },
                }}
              ></Table>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};