import axios from 'axios';
import Paths from '../config/Paths';

export async function getSongListAsync() {
    var result;
    await axios
        .get(Paths.Songs)
        .then(function (response) {
            result = response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
    return result;
}

export async function getSongByIdAsync(id) {
    var result;
    await axios
        .get(
            Paths.Song,
            {
                params: { id: id }
            })
        .then(function (response) {
            result = response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
    return result;
}

export async function deleteSongByIdAsync(id) {
    var result;
    await axios
        .delete(Paths.SongDelete, { params: { id: id }, timeout: 5000 })
        .then(function (response) {
            result = response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
    return result;
}

export async function updateSongByIdAsync(id, title, imageFile, description, lyric, isCompetition) {
    var result;
    var formData = new FormData();
    formData.append("id", id);
    formData.append("title", title);
    if (imageFile != null) {
        formData.append("image", imageFile, imageFile.name);
    }
    formData.append("introduction", description);
    formData.append("lyric", lyric);
    formData.append("isCompetition", isCompetition);
    await axios
        .post(
            Paths.SongEdit,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }
        )
        .then(function (response) {
            result = response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
    return result;
}