//const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_URL = "https://muxic.azurewebsites.net";
const IPFS_URL = "http://20.2.84.246:80";
const Paths = {
    Login: `${BASE_URL}/api/Member/login`,
    Songs: `${BASE_URL}/api/Admin/SongList`,
    Song: `${BASE_URL}/api/Admin/Song`,
    SongEdit: `${BASE_URL}/api/Admin/SongEdit`,
    SongDelete: `${BASE_URL}/api/Admin/SongDelete`,
    SongMint: `${BASE_URL}/api/wallet/mintNFT`,
    Users: `${BASE_URL}/api/Admin/UserList`,
    Payments: `${BASE_URL}/api/Admin/Payment`,
    Payment: `${BASE_URL}/api/Admin/PaymentDetail`,
    Settings: `${BASE_URL}/api/Admin/SettingList`,
    Setting: `${BASE_URL}/api/Admin/Setting`,
    SettingEdit: `${BASE_URL}/api/Admin/UpdateSetting`,
    NFTTransactionsHistory: `${BASE_URL}/api/wallet/nft`,
    NFTTasks: `${BASE_URL}/api/wallet/nfttask`,
    NFTBadges: `${BASE_URL}/api/wallet/NFTBadges`,
    NFTAirdrop: `${BASE_URL}/api/admin/airdrop`,
    UploadFile: `${BASE_URL}/api/admin/fileupload`,
    UploadFileIPFS: `${IPFS_URL}/uploadToIpfs`,
    GetEvents: `${BASE_URL}/api/ticket/events`,
    UpsertEvent: `${BASE_URL}/api/ticket/event`,
    RemoveEvent: `${BASE_URL}/api/ticket/removeevent`,
    CreateTicket: `${BASE_URL}/api/ticket/create-ticket`,
    GetAllTickets: `${BASE_URL}/api/ticket/allTickets`,
    FindTransaction: `${BASE_URL}/api/ticket/findTransaction`,
    HandmadeTicket: `${BASE_URL}/api/ticket/handmade`,
}

export default Paths;