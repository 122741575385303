import axios from 'axios';
import Paths from '../config/Paths';

export async function loginAsync(email, password) {
    var result;
    var formData = new FormData();
    formData.append("Username", email);
    formData.append("Password", password);

    await axios.post(
        Paths.Login,
        formData)
        .then(function (response) {
            console.log(response);
            result = response.data;
            localStorage["Authorization"] = result.token;
            localStorage["Username"] = result.displayname;
            result = true;
        })
        .catch(function () {
            result = false;
        })
    return result;
}