import { getPaymentByPaymentId } from "../api/payment";
import { useParams } from "react-router-dom";
import { useState, useEffect, useCallback } from 'react';
import { Form, Spin } from 'antd';
import Moment from "react-moment";

const PaymentDetail = () => {
    let { paymentId } = useParams();
    const [payment_method, setPaymentmethod] = useState("");
    const [status, setStatus] = useState("");
    const [email, setEmail] = useState("");
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [country_code, setCountryCode] = useState("");
    const [total, setTotal] = useState("");
    const [currency, setCurrency] = useState("");
    const [create_time, setCreateTime] = useState("");

    const getPaymentDetail = useCallback(async () => {
        var record = {
            "payer": {
                "payment_method": "",
                "status": "",
                "payer_info": {
                    "email": "",
                    "first_name": "",
                    "last_name": "",
                    "phone": "",
                    "country_code": ""
                }
            },
            "transactions": [
                {
                    "amount": {
                        "total": "",
                        "currency": ""
                    }
                }
            ],
            "create_time": "",
        };
        record = await getPaymentByPaymentId(paymentId);
        setPaymentmethod(record.payer.payment_method);
        setStatus(record.payer.status);
        setEmail(record.payer.payer_info.email);
        setFirstName(record.payer.payer_info.first_name);
        setLastName(record.payer.payer_info.last_name);
        setPhone(record.payer.payer_info.phone);
        setCountryCode(record.payer.payer_info.country_code);
        setTotal(record.transactions[0].amount.total);
        setCurrency(record.transactions[0].amount.currency);
        setCreateTime(record.create_time);
    }, [paymentId]);

    useEffect(() => {
        getPaymentDetail();
    }, [paymentId, getPaymentDetail]);

    return (

        <Form
            labelCol={{
                span: 4,
            }}
            wrapperCol={{
                span: 14,
            }}
            layout="horizontal">
            <Spin spinning={!payment_method}>
                <Form.Item label="Payment Method">
                    {payment_method}
                </Form.Item>
                <Form.Item label="Status">
                    {status}
                </Form.Item>
                <Form.Item label="Email">
                    {email}
                </Form.Item>
                <Form.Item label="First Name">
                    {first_name}
                </Form.Item>
                <Form.Item label="Last Name">
                    {last_name}
                </Form.Item>
                <Form.Item label="Phone">
                    {phone}
                </Form.Item>
                <Form.Item label="Country Code">
                    {country_code}
                </Form.Item>
                <Form.Item label="Total">
                    {total}
                </Form.Item>
                <Form.Item label="Currency">
                    {currency}
                </Form.Item>
                <Form.Item label="Create at">
                    <Moment date={create_time} format="YYYY-MM-DD hh:mm:ss" />
                </Form.Item>
            </Spin>
        </Form>

    );
};

export default PaymentDetail;