import { Row, Col, Card, Table, Space, Input, Button, Modal, Form } from "antd";
import ExcelButton from "../components/ExportToExcelButton";
import { getSongListAsync, deleteSongByIdAsync } from "../api/song";
import { useState, useEffect, useRef, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { TiTick } from "react-icons/ti";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import Moment from "react-moment";
import Player from "../components/Player";
import { getMetadata } from "../helper/metadata";

const { TextArea } = Input;

const Songs = () => {
  const history = useHistory();
  const [songs, setSongs] = useState([]);
  const [page, setPage] = useState(1);
  const [songId, setSongId] = useState();
  const [title, setTitle] = useState();
  const [nft, setNFT] = useState({
    id: 0,
    nfTid: "",
    amount: 0,
    elementId: "",
    type: null,
    thumbnail: null,
    title: null,
    balance: 0,
    available: 0,
    price: 0,
    allowSell: false,
    tx: "",
    network: null,
    contract: null,
    timestamp: "",
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isNFTModalVisible, setIsNFTModalVisible] = useState(false);
  const [isMetaModalVisible, setIsMetaModalVisible] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);

  const getSongList = useCallback(async () => {
    setIsSpinning(true);
    var songList;
    songList = await getSongListAsync();
    setSongs(songList);
    setIsSpinning(false);
  }, []);

  useEffect(() => {
    getSongList();
  }, [getSongList]);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const getColumnFilterProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          type={"number"}
          ref={searchInput}
          placeholder={`Search Total Likes >= ?`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex] >= value,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  async function deleteSong(id) {
    await deleteSongByIdAsync(id);
  }

  const showModal = (songId, title) => {
    setIsModalVisible(true);
    setSongId(songId);
    setTitle(title);
  };

  const showNFTModal = (nft) => {
    setNFT(nft);
    setIsNFTModalVisible(true);
  };
  const showMetadataModal = (nft) => {
    setNFT(nft);
    setIsMetaModalVisible(true);
  };

  const handleOk = async () => {
    await deleteSong(songId);
    setIsModalVisible(false);
    getSongList();
  };
  const handleMetaOk = async () => {
    setIsMetaModalVisible(false);
  };
  const handleNFTOk = async () => {
    setIsNFTModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsNFTModalVisible(false);
    setIsMetaModalVisible(false);
  };

  const columns = [
    {
      title: "Index",
      key: "index",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      ...getColumnSearchProps("title"),
      render: (_, record) => {
        return (
          <Link to={"./song/" + record.id} key={record.id + "Detail"}>
            {record.title}
          </Link>
        );
      },
    },
    {
      title: "Play Music",
      dataIndex: "url",
      key: "url",
      render: (_, record) => {
        return (
          <Space size="middle">
            <Player url={record.url} />
            <a href={record.url} download>
              Download
            </a>
          </Space>
        );
      },
    },
    {
      title: "Uploaded By",
      dataIndex: "uploadedBy",
      key: "uploadedBy",
      ...getColumnSearchProps("uploadedBy"),
    },
    {
      title: "Artist",
      dataIndex: "artist",
      key: "artist",
      ...getColumnSearchProps("artist"),
    },
    {
      title: "Upload Time",
      dataIndex: "uploadTime",
      key: "uploadTime",
      sorter: (a, b) => a.uploadTime.localeCompare(b.uploadTime),
      render: (_, record) => {
        return <Moment date={record.uploadTime} format="YYYY-MM-DD hh:mm" />;
      },
    },
    {
      title: "Is Competition",
      dataIndex: "isCompetition",
      key: "isCompetition",
      filters: [
        {
          text: "Yes",
          value: "true",
        },
        {
          text: "No",
          value: "false",
        },
      ],
      onFilter: (value, record) =>
        record.isCompetition.toString().includes(value),
      render: (_, record) => {
        return record.isCompetition ? <TiTick /> : <></>;
      },
    },
    {
      title: "Is Contract",
      dataIndex: "isContract",
      key: "isContract",
      filters: [
        {
          text: "Yes",
          value: "true",
        },
        {
          text: "No",
          value: "false",
        },
      ],
      onFilter: (value, record) => record.isContract.toString().includes(value),
      render: (_, record) => {
        return record.isContract ? <TiTick /> : <></>;
      },
    },
    {
      title: "Played Time",
      dataIndex: "play_count",
      key: "play_count",
    },
    {
      title: "Total Likes (Current Likes)",
      dataIndex: "likes",
      key: "likes",
      sorter: (a, b) => a.likes - b.likes,
      ...getColumnFilterProps("likes"),
      render: (_, record) => {
        return record.likes + "(" + record.currentLike + ")";
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {record.nft ? (
            record.nft.nfTid !== "Pending" ? (
              <Button
                style={{ color: "#22075e" }}
                onClick={() => {
                  showNFTModal(record.nft);
                }}
              >
                NFT Status
              </Button>
            ) : (
              <Button
                style={{ color: "#fadb14" }}
                onClick={() => {
                  showNFTModal(record.nft);
                }}
              >
                Pending Create
              </Button>
            )
          ) : (
            <Button
              style={{ color: "#237804" }}
              onClick={() => {
                history.push(`/song/mint/${record.id}`);
              }}
            >
              Mint NFT
            </Button>
          )}
          <Button
            onClick={() => {
              showMetadataModal(record);
            }}
          >
            Metadata
          </Button>
          <Link to={"./song/" + record.id} key={record.id + "Detail"}>
            Details
          </Link>
          <Button
            danger
            onClick={() => {
              showModal(record.id, record.title);
            }}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  function NftModal() {
    return (
      <Modal
        title={`NFT '${nft.id}'`}
        open={isNFTModalVisible}
        onOk={handleNFTOk}
        onCancel={handleCancel}
      >
        <Form
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 14,
          }}
          layout="horizontal"
        >
          <Form.Item label="NFT Id">{nft.nfTid}</Form.Item>
          <Form.Item label="Amount">{nft.amount}</Form.Item>
          <Form.Item label="Available">{nft.available}</Form.Item>
          <Form.Item label="Price">{nft.price}</Form.Item>
          <Form.Item label="Sell Available">{nft.allowSell}</Form.Item>
          <Form.Item label="Tx Id">
            <a
              rel="noreferrer"
              target="_blank"
              href={`https://mumbai.polygonscan.com/tx/${nft.tx}`}
            >
              {nft.tx}
            </a>
          </Form.Item>
          <Form.Item label="Transactions">
            <Button style={{ color: "#23785f" }}>
              <Link to={`/nft/${nft.nfTid}`}>Check Histroy</Link>
            </Button>
          </Form.Item>
          <Form.Item label="Contract">{nft.contract}</Form.Item>
          <Form.Item label="Network">{nft.network}</Form.Item>
          <Form.Item label="Timestamp">
            {<Moment date={nft.timestamp} format="YYYY-MM-DD hh:mm" />}
          </Form.Item>
        </Form>
      </Modal>
    );
  }

  function getData() {
    return getMetadata(nft);
  }
  function MetadataModal() {
    return (
      <Modal
        title={`Metadata of '${nft.title}'`}
        open={isMetaModalVisible}
        onOk={handleMetaOk}
        onCancel={handleCancel}
      >
        <TextArea autoSize value={getData()} />
      </Modal>
    );
  }

  return (
    <div>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={true}
              className="criclebox tablespace mb-24"
              title="Songs Table"
              extra={
                <ExcelButton
                  data={songs}
                  displayMessage={"Export to excel"}
                  filename={"Song-Records"}
                />
              }
            >
              <div className="table-responsive">
                <Table
                  {...{
                    loading: isSpinning,
                  }}
                  style={{ margin: "10px" }}
                  rowKey="id"
                  dataSource={songs}
                  bordered
                  columns={columns}
                  pagination={{
                    onChange(current) {
                      setPage(current);
                    },
                  }}
                ></Table>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      {NftModal()}
      {MetadataModal()}
      <Modal
        okText="Yes"
        title={`Delete Song "${title}"?`}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>
          You are deleting song "<b>{title}</b>".
          <br />
          And this action can not be revert.
          <br />
          Are you sure?
        </p>
      </Modal>
    </div>
  );
};

export default Songs;
