import {
  Button,
  Form,
  Input,
  Switch,
  Modal
} from 'antd';
import { MdArrowBack } from "react-icons/md";
import { useState } from 'react';
import moment from 'moment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';

const { TextArea } = Input;

export default function Event  (props) {
  const {item, handleDelete, onBack, handleSave} = props
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [event, setEvent] = useState(item);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const updateEvent = (key, value) => {
    setEvent({...event, [key]: value})
  }

  if (!event) return null

  return (
    <div>
      <Button onClick={() => onBack()}><MdArrowBack />Back</Button>
      <Form
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout="horizontal"
      >
        <Form.Item label="Title">
          <Input name="title" onChange={(e) => { updateEvent('title', e.target.value) }} value={event.title} />
        </Form.Item>
        <Form.Item label="Title (Chinese)">
          <Input name="title_chinese" onChange={(e) => { updateEvent('title_chinese', e.target.value) }} value={event.title_chinese} />
        </Form.Item>
        <Form.Item label="Activity Type">
          <Input name="activityType" onChange={(e) => { updateEvent('activityType', e.target.value) }} value={event.activityType} />
        </Form.Item>
        <Form.Item label="Activity Type (Chinese)">
          <Input name="activityType_chinese" onChange={(e) => { updateEvent('activityType_chinese', e.target.value) }} value={event.activityType_chinese} />
        </Form.Item>
        <Form.Item label="Category">
          <Input name="category" onChange={(e) => { updateEvent('category', e.target.value) }} value={event.category} />
        </Form.Item>
        <Form.Item label="Category (Chinese)">
          <Input name="category_chinese" onChange={(e) => { updateEvent('category_chinese', e.target.value) }} value={event.category_chinese} />
        </Form.Item>
        <Form.Item label="Venue">
          <Input name="venue" onChange={(e) => { updateEvent('venue', e.target.value) }} value={event.venue} />
        </Form.Item>
        <Form.Item label="Venue (Chinese)">
          <Input name="venue_chinese" onChange={(e) => { updateEvent('venue_chinese', e.target.value) }} value={event.venue_chinese} />
        </Form.Item>
        <Form.Item label="Description">
          <TextArea name="description" rows={4} onChange={(e) => { updateEvent('description', e.target.value) }} value={event.description} />
        </Form.Item>
        <Form.Item label="Description (Chinese)">
          <TextArea name="description_chinese" rows={4} onChange={(e) => { updateEvent('description_chinese', e.target.value) }} value={event.description_chinese} />
        </Form.Item>
        <Form.Item label="Start Date">
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            label="DateTimePicker"
            value={moment(event.startDate)}
            onChange={(newValue) => {
              updateEvent('startDate', newValue.format())
            }}
          />
        </Form.Item>
        <Form.Item label="End Date">
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            label="DateTimePicker"
            value={moment(event.endDate)}
            onChange={(newValue) => {
              updateEvent('endDate', newValue.format())
            }}
          />
        </Form.Item>
        <Form.Item label="Event Start">
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            label="DateTimePicker"
            value={moment(event.eventStart)}
            onChange={(newValue) => {
              updateEvent('eventStart', newValue.format())
            }}
          />
        </Form.Item>
        <Form.Item label="Event End">
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            label="DateTimePicker"
            value={moment(event.eventEnd)}
            onChange={(newValue) => {
              updateEvent('eventEnd', newValue.format())
            }}
          />
        </Form.Item>
        <Form.Item label="Original Price">
          <Input name="originalPrice" onChange={(e) => { updateEvent('originalPrice', e.target.value) }} value={event.originalPrice} />
        </Form.Item>
        <Form.Item label="Member Price">
          <Input name="memberPrice" onChange={(e) => { updateEvent('memberPrice', e.target.value) }} value={event.memberPrice} />
        </Form.Item>
        <Form.Item label="Quota(Member Price) per user">
          <Input name="memberQuota" onChange={(e) => { updateEvent('memberQuota', e.target.value) }} value={event.memberQuota} />
        </Form.Item>
        <Form.Item label="Quota per user">
          <Input name="publicQuota" onChange={(e) => { updateEvent('publicQuota', e.target.value) }} value={event.publicQuota} />
        </Form.Item>
        <Form.Item label="Quota">
          <Input name="quota" onChange={(e) => { updateEvent('quota', e.target.value) }} value={event.quota} />
        </Form.Item>
        <Form.Item label="Ticket Image (url)">
          <Input name="ticketImage" onChange={(e) => { updateEvent('ticketImage', e.target.value) }} value={event.ticketImage} />
        </Form.Item>
        <Form.Item label="Ticket Message (email)">
          <TextArea name="ticketMessage" rows={4} onChange={(e) => { updateEvent('ticketMessage', e.target.value) }} value={event.ticketMessage} />
        </Form.Item>
        <Form.Item label="Total Ticket Generated">
          <Input disabled value={event.totalTicketGenerated} name="totalTicketGenerated" />
        </Form.Item>
        <Form.Item label="Total Ticket Hold">
          <Input disabled value={event.totalTicketHold} name="totalTicketHold" />
        </Form.Item>
        <Form.Item label="Total Ticket Sold">
          <Input disabled value={event.totalTicketSold} name="totalTicketSold" />
        </Form.Item>
        <Form.Item label="hide">
          <Switch checked={event.hide} onChange={() => updateEvent('hide', !event.hide)} />
        </Form.Item>
        <Form.Item label="Action">
          <Button onClick={() => handleSave(event)}>Save</Button>
          <Button onClick={() => setEvent(item)}>Cancel</Button>
          <Button danger onClick={() => { showModal() }}>Delete</Button>
        </Form.Item>
      </Form>
      <Modal okText="Yes" title={`Delete Song "${event.title}"?`} visible={isModalVisible} onOk={handleDelete} onCancel={handleCancel}>
        <p >You are deleting event "<b>{event.title}</b>".<br />And this action can not be revert.<br />Are you sure?</p>
      </Modal>
    </div>
  );
};