import { Switch, Route } from "react-router-dom";
import SignIn from "./pages/SignIn";
import Songs from "./pages/Song";
import Main from "./components/layout/Main";
import AxiosConfig from './config/AxiosConfig';
import SongDetail from "./pages/SongDetail";
import Users from "./pages/User";
import Payments from "./pages/Payment";
import PaymentDetail from "./pages/PaymentDetail";
import Setting from "./pages/Setting";
import SettingDetail from "./pages/SettingDetail";
import SongMint from "./pages/SongMint";
import NFTTransaction from "./pages/NFTTransaction";
import NFTTask from "./pages/NFTTask";
import NFTItems from "./pages/NFTItems";
import FileUpload from "./pages/FileUpload";
import NFTAirdrop from "./pages/NFTAirdrop";
import Events from "./pages/Events";
import EventTicketRecord from "./pages/Events/EventTicketRecord";
import TicketDetail from "./pages/Events/TicketDetail";
import FileUploadIPFS from "./pages/FileUploadIPFS";

AxiosConfig();

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/" exact component={SignIn} />
        <Main>
          <Route exact path="/song" component={Songs} />
          <Route exact path="/song/:id" component={SongDetail} />
          <Route exact path="/song/mint/:id" component={SongMint} />
          <Route exact path="/user" component={Users} />
          <Route exact path="/nft-task" component={NFTTask} />
          <Route exact path="/airdrop" component={NFTAirdrop} />
          <Route exact path="/nft/:nftId" component={NFTTransaction} />
          <Route exact path="/nft-item" component={NFTItems} />
          <Route exact path="/payment" component={Payments} />
          <Route exact path="/payment/:paymentId" component={PaymentDetail} />
          <Route exact path="/setting" component={Setting} />
          <Route exact path="/setting/:paramKey" component={SettingDetail} />
          <Route exact path="/setting/action/create" component={SettingDetail} />
          <Route exact path="/file/upload" component={FileUpload} />
          <Route exact path="/file/ipfs/upload" component={FileUploadIPFS} />
          <Route exact path="/event" component={Events} />
          <Route exact path="/event-tickets/:id" component={EventTicketRecord} />
          <Route exact path="/event-ticket/:id" component={TicketDetail} />
        </Main>
      </Switch>
    </div>
  );
}

export default App;
