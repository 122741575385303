import axios from 'axios';

function AxiosConfig() {
    axios.interceptors.request.use(
        (req) => {
            const token = localStorage["Authorization"];
            req.headers.Authorization = token ? `Bearer ${token}` : '';
            return req;
        },
        (err) => {
            return Promise.reject(err);
        }
    );

    axios.interceptors.response.use(
        null,
        (err) => {
            if (err.response.status === 401) {
                localStorage.clear();
                window.location = '/';
            }
            else if (err.response.status === 404) {
                localStorage.clear();
                window.location = '/';
            }
            return Promise.reject(err);
        }
    );
}

export default AxiosConfig