import axios from 'axios';
import Paths from '../config/Paths';

export async function uploadFileAsync(file, fileType) {
    var result;
    var formData = new FormData();
    formData.append("file", file);
    formData.append("folder", fileType);

    await axios.post(
        Paths.UploadFile,
        formData)
        .then(function (response) {
            result = response.data;
        })
        .catch(function () {
            result = false;
        })
    return result;
}

export async function uploadFileToIpfsAsync(url, filename) {
    var result;
    var formData = new FormData();
    formData.append("url", url);
    formData.append("filename", filename);

    await axios.post(
        Paths.UploadFileIPFS,
        formData)
        .then(function (response) {
            result = response.data;
        })
        .catch(function () {
            result = false;
        })
    return result;
}