import axios from 'axios';
import Paths from '../config/Paths';

export async function mintNFTAsync(songId, quantity) {
    var result;
    await axios
        .get(Paths.SongMint, { params: { id: songId, amount: quantity } })
        .then(function (response) {
            result = response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
    return result;
}

export async function getTransactionsById(nftId) {
    var result;
    await axios
        .get(Paths.NFTTransactionsHistory, { params: { id: nftId, } })
        .then(function (response) {
            result = response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
    return result;
}

export async function getNFTTasks() {
    var result;
    await axios
        .get(Paths.NFTTasks)
        .then(function (response) {
            result = response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
    return result;
}

export async function getNFTBadges() {
    var result;
    await axios
        .get(Paths.NFTBadges)
        .then(function (response) {
            result = response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
    return result;
}

export async function airdropAsync(NFTid, users, amount) {
    var result;
    var formData = new FormData();
    formData.append("NFTid", NFTid);
    formData.append("users", users);
    formData.append("amount", amount);
    await axios
        .post(
            Paths.NFTAirdrop,
            formData)
        .then(function (response) {
            result = response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
    return result;
}