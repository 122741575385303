import { useState, useEffect, useCallback } from "react";
import { Button, Form, Input, Spin, message, Row, Col } from "antd";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { getSettingByKey, updateSettingAsync } from "../api/setting";
import { useParams, useHistory } from "react-router-dom";
import { MdOutlineAdd } from "react-icons/md";

const { TextArea } = Input;

const SettingDetailV2 = () => {
  let history = useHistory();
  let { paramKey } = useParams();
  const [key, setKey] = useState("");
  const [content, setContent] = useState("");
  const [isCreate, setIsCreate] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const [isAnnouncement] = useState(paramKey === "announcement");
  const [announcement, setAnnouncement] = useState([""]);

  const getSettingDetail = useCallback(async () => {
    setIsSpinning(true);
    var record = {
      key: "",
      content: "",
    };
    record = await getSettingByKey(paramKey);

    if (record === "") {
      setIsCreate(true);
    }
    setKey(record.key);
    if (isAnnouncement) {
      setAnnouncement(record.content.split(";;;\r\n"));
    } else {
      setContent(record.content || "");
    }
    setIsSpinning(false);
  }, [paramKey, isAnnouncement]);

  useEffect(() => {
    getSettingDetail();
  }, [paramKey, getSettingDetail]);

  async function updateSetting() {
    if (isCreate && (await getSettingByKey(key)) !== "") {
      message.error(`Key "${key}" already been used. Please use another key.`);
      return;
    }
    setIsSpinning(true);
    if (isAnnouncement) {
      await updateSettingAsync(key, announcement.join(";;;\r\n"));
    } else {
      await updateSettingAsync(key, content);
    }

    if (isCreate) {
      history.goBack();
    }
    getSettingDetail();
    message.success("Update success.");
  }

  const onReset = () => {
    getSettingDetail();
  };

  const getCurrentDate = () => {
    var today = new Date();
    return `[${today.getDate()}.${today.getMonth() + 1}.${
      today.getFullYear() % 100
    }]`;
  };

  const removeAnnouncement = (index) => {
    let newArr = [...announcement];
    newArr.splice(index, 1);
    setAnnouncement(newArr);
  };

  const updateAnnouncement = (index, text) => {
    let newArr = [...announcement];
    newArr[index] = text;
    setAnnouncement(newArr);
  };

  const addAnnouncement = () => {
    let newArr = [...announcement];
    newArr.unshift(getCurrentDate() + " ");
    setAnnouncement(newArr);
  };

  const AnnouncementTable = () => {
    var rows = announcement.map((dataRow, index) => {
      return (
        <div key={index}>
          <Row style={{ marginBottom: "5px" }} className="row" key={index}>
            <Col span={4}>
              <Input
                value={dataRow.split(" ")[0]}
                onChange={(e) => {
                  updateAnnouncement(
                    index,
                    e.target.value +
                      " " +
                      dataRow.split(" ").slice(1).join(" ").replace(";;;", "")
                  );
                }}
              />
            </Col>
            <Col span={16}>
              <TextArea
                autoSize
                value={dataRow.split(" ").slice(1).join(" ").replace(";;;", "")}
                onChange={(e) => {
                  updateAnnouncement(
                    index,
                    dataRow.split(" ")[0] + " " + e.target.value
                  );
                }}
              />
            </Col>
            <Col span={4}>
              <Button
                onClick={() => {
                  removeAnnouncement(index);
                }}
              >
                Remove
              </Button>
            </Col>
          </Row>
        </div>
      );
    });

    return (
      <div>
        <Button style={{ marginBottom: "5px" }} onClick={addAnnouncement}>
          <MdOutlineAdd />
          Add an announcement
        </Button>
        {rows}
      </div>
    );
  };

  return (
    <Form
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 14,
      }}
      layout="horizontal"
    >
      <Spin spinning={isSpinning}>
        <Form.Item label="Key">
          <Input
            value={key}
            onChange={(e) => {
              setKey(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item label="Content">
          {paramKey === "announcement" ? (
            AnnouncementTable()
          ) : (
            <TextArea
              rows={12}
              value={content}
              onChange={(e) => {
                setContent(e.target.value);
              }}
            />
          )}
        </Form.Item>

        <Form.Item label="Action">
          <Button
            onClick={() => {
              updateSetting();
            }}
          >
            Save
          </Button>
          <Button
            onClick={() => {
              onReset();
            }}
          >
            Cancel
          </Button>
        </Form.Item>
      </Spin>
    </Form>
  );
};

export default SettingDetailV2;
