import React, { useState } from "react";
import { Button, TreeSelect, Form, Upload, Spin, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { uploadFileAsync } from "../api/action";
const { Dragger } = Upload;

const FileUpload = () => {
  const treeData = [
    { title: "Image", value: "image" },
    { title: "MP3", value: "mp3" },
    { title: "Document", value: "document" },
  ];
  const [fileList, setFileList] = useState([]);
  const [isSpinning, setIsSpinning] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState();
  const [result, setResult] = useState();
  const [fileType, setFileType] = useState("*.*");

  function changeSelectedFileType(value) {
    if (value === "mp3") {
      setFileType(".mp3");
    } else if (value === "document") {
      setFileType("*.*");
    } else if (value === "image") {
      setFileType("image/*");
    } else {
      setFileType("*.*");
    }
    setSelectedFileType(value);
  }

  function customRequest({ file, onError, onSuccess }) {
    if (fileList.length === 1) {
      message.info("accept 1 file only for each request");
    }
    if (file.type.startsWith("audio/")) {
      changeSelectedFileType("mp3");
    } else if (file.type.startsWith("image/")) {
      changeSelectedFileType("image");
    } else {
      changeSelectedFileType("document");
    }
    setFileList([file]);
    return;
  }

  async function uploadFile() {
    if (fileList.length < 1) {
      message.warning("Please select a file first.");
      return;
    }
    setIsSpinning(true);
    setResult(await uploadFileAsync(fileList[0], selectedFileType));
    resetForm();
    message.success("Upload success!");
    setIsSpinning(false);
  }

  function resetForm() {
    setFileList([]);
    setSelectedFileType();
  }

  return (
    <div>
      <Form
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout="horizontal"
      >
        <Spin spinning={isSpinning}>
          <Form.Item label="File Type">
            <TreeSelect
              showSearch
              style={{
                width: "100%",
              }}
              value={selectedFileType}
              dropdownStyle={{
                maxHeight: 400,
                overflow: "auto",
              }}
              placeholder="Please select upload file type"
              allowClear
              treeDefaultExpandAll
              onChange={(v) => changeSelectedFileType(v)}
              treeData={treeData}
            />
          </Form.Item>
          <Form.Item label="Select File">
            <Dragger
              name="file"
              maxCount="1"
              accept={fileType}
              customRequest={customRequest}
              multiple={false}
              fileList={fileList}
              onRemove={() => {
                setFileList([]);
              }}
            >
              <p className="ant-upload-drag-icon">
                <UploadOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">Support for a single upload.</p>
            </Dragger>
          </Form.Item>
          <Form.Item label="Action">
            <Button
              onClick={() => {
                uploadFile();
              }}
            >
              Upload
            </Button>
            <Button onClick={resetForm}>Cancel</Button>
          </Form.Item>
        </Spin>
        {typeof result != "undefined" ? (
          <Form.Item label="Result">
            <TextArea disabled value={result} rows={4}></TextArea>
          </Form.Item>
        ) : (
          <></>
        )}
      </Form>
    </div>
  );
};

export default FileUpload;
