import { uploadFileToIpfsAsync } from "../api/action";

export function getMetadata(nft) {
    var url = Promise.resolve(uploadFileToIpfsAsync(nft.url, nft.title + ".mp3")).then((value) => {
        return value;
    });
    var metadataObject = {
        name: nft.title,
        animation_url: url,
        external_url: `https://muxic.io/${nft.id}`,
        attributes: [
            {
                trait_type: "Artist",
                value: nft.artist,
            },
            {
                trait_type: "Owner",
                value: nft.artist,
            },
            {
                display_type: "date",
                trait_type: "Upload Time",
                value: Math.floor(new Date(nft.uploadTime).getTime() / 1000),
            },
            {
                trait_type: "Lyric",
                value: nft.lyric,
            },
        ],
    };
    return JSON.stringify(metadataObject, null, 4);
}