import axios from 'axios';
import Paths from '../config/Paths';
import _ from 'lodash'
import moment from 'moment';

export async function getEvents() {
  try {
    const result = await axios.get(Paths.GetEvents)
    const events = _.flatten(_.values(result.data)).map((e) => {
      return {
        ...e,
        startDate: moment(e.startDate).add(8, 'h').format(),
        endDate: moment(e.endDate).add(8, 'h').format(),
        eventStart: moment(e.eventStart).add(8, 'h').format(),
        eventEnd: moment(e.eventEnd).add(8, 'h').format(),
      }
    })
    return events
  } catch (error) {
    console.log(error)
    return []
  }
}

export async function removeEvent(id) {
  try {
    await axios.delete(Paths.RemoveEvent, {
      params: { id }
    })
  } catch (error) {
    console.log(error)
  }
}

export async function upsertEvent(data) {
  try {
    const formData = new FormData()
    Object.keys(data).forEach(key => {
      if (typeof data[key] !== 'object') formData.append(key, data[key])
      else formData.append(key, JSON.stringify(data[key]))
    })
    await axios.post(Paths.UpsertEvent, formData)
  } catch (error) {
    console.log(error)
  }
}

export async function createTicket(eventId, ticketList) {
  try {
    await axios.get(Paths.CreateTicket, {
      params: { eventId, ticketList }
    })
  } catch (error) {
    console.log(error)
  } 
}

export async function allTickets(id) {
  try {
    const result = await axios.get(Paths.GetAllTickets)
    const event = _.find(result.data, data => {
      return String(data.eventDetail?.id) === id
    })
    return event
  } catch (error) {
    console.log(error)
    return {}
  }
} 

export async function findTransaction(refId) {
  try {
    const result = await axios.get(Paths.FindTransaction, {
      params: { refId }
    })
    return result.data
  } catch (error) {
    console.log(error)
    return {}
  }
}

export async function handmadeTicket(data) {
  try {
    const formData = new FormData()
    Object.keys(data).forEach(key => {
      if (typeof data[key] !== 'object') formData.append(key, data[key])
      else formData.append(key, JSON.stringify(data[key]))
    })
    await axios.post(Paths.HandmadeTicket, formData)
  } catch (error) {
    console.log(error)
  }
}