import React, { useEffect, useState } from "react";
import { Col, Button, Row, Form, Card } from "react-bootstrap";
import { loginAsync } from '../api/authorization';
import { useHistory } from "react-router-dom";
import { message } from "antd";

const SignIn = () => {

  let history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (localStorage["Authorization"] != null) {
      history.push("./song");
    }
  }, [history]);

  function validateForm() {
    return email.length > 0 && password.length > 6;
  }

  async function handleSubmit(event) {
    try {
      event.preventDefault();
      var isLoginSuccess = await loginAsync(email, password) || false;
      if (isLoginSuccess) {
        history.push("/song");
      } else {
        message.error("Incorrect Username/Password");
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <Row style={{ marginTop: "50px" }} className="mx-4 justify-content-center">
      <Col md={8} lg={6} xs={12}>
        <Card className="shadow">
          <Card.Body>
            <div className="mb-3 mt-md-4">
              <h2 className="fw-bold mb-3 text-uppercase">Muxic</h2>
              <h4 className="fw-bold mb-1">Login</h4>
              <div className="mb-3">
                <Form onSubmit={handleSubmit} >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="text-center">
                      Email address
                    </Form.Label>
                    <Form.Control
                      autoFocus
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="formBasicCheckbox"
                  >
                  </Form.Group>
                  <div className="d-grid">
                    <Button block="true" size="lg" type="submit" disabled={!validateForm()}>
                      Login
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
export default SignIn;