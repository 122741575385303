import React, { useState, useEffect } from "react";
import { Button, TreeSelect, Input, Form, Spin, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { getNFTBadges, airdropAsync } from "../api/nft";

const NFTAirdrop = () => {
  const [treeData, setTreeData] = useState([{ title: "", value: "" }]);
  const [isSpinning, setIsSpinning] = useState(false);
  const [nftId, setNftId] = useState();
  const [result, setResult] = useState();
  const [amount, setAmount] = useState();
  const [userIDs, setUserIDs] = useState();

  async function sendNFT() {
    if (
      typeof nftId == "undefined" ||
      typeof amount == "undefined" ||
      typeof userIDs == "undefined"
    ) {
      message.warning("Please insert all the fileds!");
      return;
    }
    setIsSpinning(true);
    setResult(await airdropAsync(nftId, userIDs, amount));
    resetForm();
    message.success("Send success!");
    setIsSpinning(false);
  }

  function resetForm() {}

  async function getBadgeList() {
    let badges = await getNFTBadges();
    setTreeData(
      badges.map(function (item) {
        return { title: `${item.title} (${item.nfTid})`, value: item.nfTid };
      })
    );
  }
  useEffect(() => {
    getBadgeList();
  }, []);

  return (
    <div>
      <Form
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout="horizontal"
      >
        <Spin spinning={isSpinning}>
          <Form.Item label="NFT">
            <TreeSelect
              aria-required
              showSearch
              style={{
                width: "100%",
              }}
              value={nftId}
              dropdownStyle={{
                maxHeight: 400,
                overflow: "auto",
              }}
              placeholder="Please select a NFT"
              allowClear
              treeDefaultExpandAll
              onChange={(v) => setNftId(v)}
              treeData={treeData}
            />
          </Form.Item>
          <Form.Item label="User IDs">
            <TextArea
              placeholder="User Id Seperated By Comma"
              rows={4}
              onChange={(e) => {
                setUserIDs(e.target.value);
              }}
            ></TextArea>
          </Form.Item>
          <Form.Item label="Amount">
            <Input
              placeholder="The number of tokens send to per user."
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="Action">
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                sendNFT();
              }}
            >
              Send
            </Button>
            <Button onClick={resetForm}>Cancel</Button>
          </Form.Item>
        </Spin>
        {typeof result != "undefined" ? (
          <Form.Item label="Result">
            <TextArea disabled value={result} rows={4}></TextArea>
          </Form.Item>
        ) : (
          <></>
        )}
      </Form>
    </div>
  );
};

export default NFTAirdrop;
