import React, { useState, useEffect } from "react";
import { BsPlay, BsFillPauseFill } from "react-icons/bs";
import {
    Button,
} from "antd";

const useAudio = (url) => {
    const [audio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(false);

    const toggle = () => setPlaying(!playing);

    useEffect(() => {
        playing ? audio.play() : audio.pause();
    },
        [playing, audio]
    );

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false));
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, [audio]);

    return [playing, toggle];
};

const Player = ({ url }) => {
    const [playing, toggle] = useAudio(url);

    return (
        <div>
            <Button style={{ color: "#22075e" }} onClick={toggle}>
                {playing ? (<div><BsFillPauseFill />Pause</div>) : <div><BsPlay />Play</div>}
            </Button >
        </div>
    );
};

export default Player;