import axios from 'axios';
import Paths from '../config/Paths';

export async function getUserListAsync() {
    var result;
    await axios
        .get(Paths.Users)
        .then(function (response) {
            result = response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
    return result;
}